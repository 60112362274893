import '../styles/style.scss'
import { AnimatePresence } from 'framer-motion'

function ���ϱ���App({ Component, pageProps, router }) {
  return (
      <AnimatePresence mode="wait" initial={true} onExitComplete={() => window.scrollTo(0, 0)}>
          <Component {...pageProps} />
      </AnimatePresence>
    )
}

export default ���ϱ���App